<template>
  <v-container>

    <h1>{{room.name}}</h1>

    <hr style="height: 1px" class="mt-2 mb-6"/>

    <v-card v-if="room" tile flat>
      <v-img v-if="room.background_image" :src="'https://directus.raumcloud.com/assets/' + room.background_image.id" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="300px">
        <!--<v-card-title v-text="room.name" />-->
      </v-img>
      <v-img v-else src="/panos-sakalakis-63sI4HO30tw-unsplash.jpg" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
        <!--<v-card-title v-text="room.name" />-->
      </v-img>

      <v-card-text v-html="room.description" />
      <v-card-text>

        <v-row>
          <v-col cols="12" sm="6">
            <h2>Raum</h2><br />
            <b>Fläche <br /></b>{{room.area}} m²
            <span v-if="room.stage_area"><br /><b>davon Bühne <br /></b> {{room.stage_area}} m²</span><br />
            <b>Länge <br /></b>{{room.length}} m<br />
            <b>Breite <br /></b>{{room.width}} m<br />
            <b>Höhe <br /></b>{{room.height}} m
          </v-col>
          <v-col cols="12" sm="6">
            <h2>Preise excl. MwSt.</h2><br />

            <span v-if="room.rent_hour">
              <b>Stunde <br /></b>{{room.rent_hour}} €<br />
            </span>
            
            <span v-if="room.rent_day">
              <b>Tag <br /></b> {{room.rent_day}} €<br />
            </span>
            
            <span v-if="room.rent_week">
              <b>Woche <br /></b> {{room.rent_week}} €<br />
            </span>
            
            <span v-if="room.rent_month">
              <b>Monat <br /></b> {{room.rent_month}} €
            </span>
            
            <span v-if="room.rent_other">
              <h4>Sonstiges</h4>
              <p>
                {{room.rent_other}}
              </p>
            </span>
          </v-col>
          <v-col cols="12" sm="6">
            <h2>Nutzung & Technik</h2><br />
            <span v-if="room.period_of_rest"><b>Nutzungszeiten:  <br /></b>{{room.period_of_rest}} <br /></span>
            <span v-if="room.period_of_use"><b>Ruhezeiten <br /></b> {{room.period_of_use}} <br /></span>
            <b>Zuschauer möglich <br /></b> {{room.event_option ? room.event_option : 'nein'}} <br />
            <b>Nutzung <br /></b> <v-chip small label class="ma-1" v-for="tag in room.tags_usage" :key="tag.tags_usage_id.name" :color="tag.tags_usage_id.color">{{tag.tags_usage_id.name}}</v-chip><br />
            <span v-if="room.usage_other"><b>Sonstiges <br /></b> {{room.usage_other}} <br /></span>

            <b>Ausstattung <br /></b><v-chip small label class="ma-1" v-for="tag in room.tags_equipment" :key="tag.tags_equipment_id.name" :color="tag.tags_equipment_id.color">{{tag.tags_equipment_id.name}}</v-chip><br />
          </v-col>
          <v-col cols="12" sm="6" v-if="room.institution_id">
            <h2>Kontakt</h2>
            <br />
            <a :href="'/institution/' + room.institution_id.id">{{room.institution_id.name}}</a>
            <span v-html="room.institution_id.kontakt" />
            <br />
            <span v-if="room.institution_id.contact_mail">Mail: <a :href="'mailto:'+room.institution_id.contact_mail" target="_blank">{{room.institution_id.contact_mail}}</a></span>
            <br />
            <span v-if="room.institution_id.contact_phone">Telefon: {{room.institution_id.contact_phone}}</span>
            <br />
            <span v-if="room.institution_id.website"><a :href="improveURL(room.institution_id.website)" target="_blank">{{room.institution_id.website}}</a></span>
          </v-col>
        </v-row>
      </v-card-text>
      
      <v-row v-if="room.images" justify="center">
        <v-col cols="12" sm="8">
        <v-carousel v-if="room.images.length > 0" :height="$vuetify.breakpoint.mobile ? 300 : 600" show-arrows-on-hover hide-delimiter-background>
          <v-carousel-item v-for="(item,i) in room.images" :key="i" contain :src="'https://directus.raumcloud.com/assets/' + item.directus_files_id" />
        </v-carousel>
        </v-col>
      </v-row>
    
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: 'Room',
  data: () => ({
    room: {},
  }),
  async created() {
    this.room = await this.$client.items("room").readOne(this.$route.params.id, { fields: ['*.*, tags_equipment.tags_equipment_id.*, tags_usage.tags_usage_id.*, images.directus_files_id']})
  },
  methods: {
    improveURL(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://'  + url;
      }
      console.log(url)
      return url
    }
  }
}
</script>