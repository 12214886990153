<template>
  <v-container>
    <span v-if="text" v-html="text" />
  </v-container>
</template>

<script>

export default {
  name: 'Was ist',
  data: () => ({
    text: null,
  }),
  async created() {
    let temp = await this.$client.items("was_ist_die_raumcloud").readMany({ fields: ['*.*']})

    this.text = temp.data.text
  }
}
</script>