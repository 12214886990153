<template>
  <v-container>

    <h1>{{institution.name}}</h1>

    <hr style="height: 1px" class="mt-2 mb-6"/>

    <v-card tile flat >
      <v-img v-if="institution.images && institution.images.length >0" :src="'https://directus.raumcloud.com/assets/' + institution.images[0].directus_files_id" class="white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="200px">
        <!--<v-card-title v-text="institution.name"/>-->
      </v-img>
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" sm="4">
            <h2>Beschreibung</h2>
            <v-card-text v-html="institution.description" />
          </v-col>
          <v-col cols="12" sm="4">
            <h2>Kontakt</h2>
            <v-card-text v-html="institution.contact" />
            <br />
            <span v-if="institution.contact_mail">Mail: <a :href="'mailto:'+institution.contact_mail" target="_blank">{{institution.contact_mail}}</a></span>
            <br />
            <span v-if="institution.contact_phone">Telefon: {{institution.contact_phone}}</span>´
            <br />
            <span v-if="institution.website"><a :href="improveURL(institution.website)" target="_blank">{{institution.website}}</a></span>
          </v-col>
          <v-col cols="12" sm="4">
            <h2> Karte </h2>
            <div ref="map-root" style="border: solid 1px black; height: 300px"></div>
          </v-col>
        </v-row>
        <h2 class="mt-2"> Räume </h2>
        <v-row>
          <v-col cols="12" v-for="room in institution.rooms" :key="room.id" >
            <hr />
            <RoomCard :room="room" />
          </v-col>
        </v-row>
      </v-card-text>
    
    </v-card>
  </v-container>

</template>

<script>

import RoomCard from '@/components/RoomCard.vue'

import 'ol/ol.css';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import {Vector as VectorLayer} from 'ol/layer';
import {Style, Icon} from 'ol/style';
import Point from 'ol/geom/Point';
import Feature from 'ol/Feature';
import {fromLonLat} from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';

function greyscale(context) {
  var canvas = context.canvas;
  var width = canvas.width;
  var height = canvas.height;
  var imageData = context.getImageData(0, 0, width, height);
  var data = imageData.data;

  for(var i=0; i<data.length; i += 4){
    var r = data[i];
    var g = data[i + 1];
    var b = data[i + 2];
    // CIE luminance for the RGB
    var v = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    // Show white color instead of black color while loading new tiles:
    if(v === 0.0)
    v=255.0;  
    data[i+0] = v; // Red
    data[i+1] = v; // Green
    data[i+2] = v; // Blue
    data[i+3] = 255; // Alpha
  }
  context.putImageData(imageData,0,0);
}

export default {
  name: 'Institution',
  components: {
    RoomCard,
  },
  data: () => ({
    institution: {},
    map: null
  }),
  async created() {
    this.institution = await this.$client.items("institution").readOne(this.$route.params.id, { fields: ['*.*, rooms.images.*, rooms.tags_usage.*.*, rooms.tags_equipment.*.*']})
  },
  updated() {
    this.initMap();
  },
  methods: {
    initMap: function () {

        var icon = new Feature({geometry: new Point(fromLonLat([this.institution.coordinates.coordinates[0], this.institution.coordinates.coordinates[1]]))})

        var iconLayerSource = new VectorSource({ features: [icon]})
   
        var iconStyle = new Style({
              image: new Icon({
                color: '#ffd500',
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: '/map-marker-border.png',
              }),
            })

        var iconLayer = new VectorLayer({source: iconLayerSource, style: iconStyle})

        var grayOsmLayer = new TileLayer({
          source: new OSM()
        });

        grayOsmLayer.on('postrender', function(event) {
          greyscale(event.context)
        });

        this.map = new Map({target: this.$refs['map-root'],
                          layers: [
                            grayOsmLayer,
                            iconLayer
                          ],
                          view: new View({
                            zoom: 11,
                            minZoom: 10,
                            maxZoom: 19,
                            center: fromLonLat([this.institution.coordinates.coordinates[0], this.institution.coordinates.coordinates[1]]),
                            constrainResolution: true
                          }),
                        })
    },
    improveURL(url) {
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://'  + url;
      }
      console.log(url)
      return url
    }
  }
}
</script>